// eslint-disable no-undefs
// @ts-nocheck
// @ts-ignore
import {
    DialogContent,
    HStack,
    Input,
    SelectContent,
    SelectItem,
    SelectRoot,
    SelectTrigger,
    SelectValueText,
    Stack,
    Text
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {DialogActionTrigger, DialogBody, DialogFooter, DialogHeader, DialogRoot, DialogTitle} from "./ui/dialog";
import {Field} from "./ui/field";
import {Button} from "./ui/button";
import {addUser} from "../api/user";
import {decodeJwt, getUserRole, isShowAdminContent} from "../utils/tokenUtils";

const AddUserModal = ({isCreateModelOpened, setIsCreateModelOpened, fetchData}) => {
    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [patronymicName, setPatronymicName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [jobTitle, setJobTitle] = useState<string>("");
    const [role, setRole] = useState<string>("")
    const [inn, setInn] = useState<string>("");
    const [brandCode, setBrandCode] = useState<string>("");
    const [organizationInn, setOrganizationInn] = useState<string>("");
    const [isAddButtonDisable, setIsAddButtonDisable] = useState(true);
    const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);
    const [isAlreadyExistsError, setIsAlreadyExistsError] = useState(false);
    const [isInnError, setIsInnError] = useState(false);
    const [isError, setError] = useState(false);

    useEffect(() => {
        setIsAddButtonDisable(!(
            login != "" && /^[a-zA-Z0-9~!?@#$%^&*_\-+()\[\]{}<>/\\|.,:;]*/.test(login)
            && /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/g.test(password)
            && lastName != ""
            && firstName != ""
            && patronymicName != ""
            && role != ""
            && jobTitle != ""
            && /^(\d{10}|\d{12})$/.test(organizationInn)
        ));
    }, [login, password, lastName, jobTitle, role, organizationInn, firstName, patronymicName]);

    const addUserHandler = async () => {
        setIsAddButtonLoading(true)
        setIsAlreadyExistsError(false)
        setIsInnError(false)
        setError(false)

        try {
            await addUser({
                login,
                password,
                firstName,
                lastName,
                patronymicName,
                userRole: role,
                jobTitle,
                organizationInn,
                inn,
                brandCode
            })
            setIsCreateModelOpened(false)
            await fetchData()
        } catch (e) {
            // @ts-ignore
            if (e.response.data.message.includes("User already exists")) {
                setIsAlreadyExistsError(true)
            } else if (e.response.data.message.includes("Organization not found")) {
                setIsInnError(true)
            } else {
                setError(true)
            }

        }

        setIsAddButtonLoading(false)
    }

    useEffect(() => {
        setIsAddButtonLoading(false)
        setIsAddButtonDisable(true)
        setIsAlreadyExistsError(false)
        setIsInnError(false)
        setError(false)

        setLogin("")
        setPassword("")
        setFirstName("")
        setPatronymicName("")
        setLastName("")
        setJobTitle("")
        setRole("")
        setInn("")
        setBrandCode("")
        setOrganizationInn(isShowAdminContent() ? "" : decodeJwt("organizationInn"))
    }, [isCreateModelOpened]);

    return (
        <HStack position="absolute" width="100%" style={{ marginTop: '-140px', height: '100vh', zIndex: isCreateModelOpened ? 1000 : -1000 }}>
            <DialogRoot open={isCreateModelOpened} placement="top" scrollBehavior='inside'>
                <DialogContent>
                    <DialogHeader style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <DialogTitle>Добавление пользователя</DialogTitle>
                    </DialogHeader>
                    <DialogBody>
                        <form>
                            <Stack>
                                <Field label="Логин пользователя" required color="#88898A">
                                    <Input value={login} onChange={e => setLogin(e.target.value)} placeholder="Логин"
                                           color="#2F4F4F"/>
                                </Field>
                                <Field label="Пароль пользователя" helperText="мин. 8 символов, мин. одна цифра, мин. одна заглавная буква и одна строчная, только латинские буквы, мин. один спец символ" required color="#88898A">
                                    <Input value={password} onChange={e => setPassword(e.target.value)}
                                           placeholder="Пароль"
                                           color="#2F4F4F"/>
                                </Field>
                                <Field label="Фамилия пользователя" required color="#88898A">
                                    <Input value={lastName} onChange={e => setLastName(e.target.value)}
                                           placeholder="Фамилия" color="#2F4F4F"/>
                                </Field>
                                <Field label="Имя пользователя" required color="#88898A">
                                    <Input value={firstName} onChange={e => setFirstName(e.target.value)}
                                           placeholder="Имя"
                                           color="#2F4F4F"/>
                                </Field>
                                <Field label="Отчество пользователя" required color="#88898A">
                                    <Input value={patronymicName} onChange={e => setPatronymicName(e.target.value)}
                                           placeholder="Отчество" color="#2F4F4F"/>
                                </Field>
                                <Field label="Должность пользователя" required color="#88898A">
                                    <Input value={jobTitle} onChange={e => setJobTitle(e.target.value)}
                                           placeholder="Должность" color="#2F4F4F"/>
                                </Field>
                                <Field label="Роль пользователя" required color="#88898A">
                                    <SelectRoot cursor="pointer" collection={getUserRole()}
                                                onValueChange={({value}) => setRole(value[0])}>
                                        <SelectTrigger cursor="pointer">
                                            <SelectValueText color="#2F4F4F" placeholder="Выбрать роль пользователя"/>
                                        </SelectTrigger>
                                        <SelectContent cursor="pointer" position="absolute" width="100%"
                                                       color="#2F4F4F">
                                            {getUserRole().items.map((movie) => (
                                                <SelectItem cursor="pointer" item={movie} key={movie.value}>
                                                    {movie.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </SelectRoot>
                                </Field>
                                <Field disabled={!isShowAdminContent()} label="ИНН огранизации пользователя (10  или 12 цифр)" required color="#88898A">
                                    <Input value={organizationInn} onChange={e => setOrganizationInn(e.target.value)}
                                           placeholder="ИНН огранизации" color="#2F4F4F"/>
                                </Field>
                                <Field label="Шифр клейма пользователя" color="#88898A">
                                    <Input value={brandCode} onChange={e => setBrandCode(e.target.value)}
                                           placeholder="Шифр клейма" color="#2F4F4F"/>
                                </Field>
                                {isAlreadyExistsError &&
                                    <Text textStyle="sm" color="red">Пользователь с таким логином уже есть в
                                        базе</Text>}
                                {isInnError &&
                                    <Text textStyle="sm" color="red">Организации с таким ИНН нет в базе</Text>}
                                {isError &&
                                    <Text textStyle="sm" color="red">Что-то пошло не так, попробуйте еще раз</Text>}
                            </Stack>
                        </form>
                    </DialogBody>
                    <DialogFooter>
                        <DialogActionTrigger asChild>
                            <Button variant="outline" onClick={() => setIsCreateModelOpened(false)}
                                    disabled={isAddButtonLoading}>Отмена</Button>
                        </DialogActionTrigger>
                        <Button disabled={isAddButtonDisable} loading={isAddButtonLoading}
                                onClick={addUserHandler}>Добавить</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot> </HStack>
    )
}

export default AddUserModal