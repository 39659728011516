// @ts-nocheck
// @ts-ignore
import {DialogContent, Flex, HStack, Table} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Button} from "./ui/button";
import {PaginationNextTrigger, PaginationPageText, PaginationPrevTrigger, PaginationRoot} from "./ui/pagination";
import {PageType} from "../type/CommonType";
import AddUserModal from "./AddUserModal";
import {deleteUser, getUsers} from "../api/user";
import Loader from "./Loader";
import {FaPenToSquare, FaTrashCan} from "react-icons/fa6";
import {DialogActionTrigger, DialogBody, DialogFooter, DialogHeader, DialogRoot, DialogTitle} from "./ui/dialog";
import UpdateUserModel from "./UpdateUserModel";

const User = () => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState({content: [{id: 1, login: "", firstName: ""}], page: {totalElements: 1}});
    const [updateUser, setUpdateUser] = useState({});
    const [isDeleteModelOpened, setIsDeleteModelOpened] = useState(false);
    const [isUpdateModelOpened, setIsUpdateModelOpened] = useState(false);
    const [isCreateModelOpened, setIsCreateModelOpened] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState<number | null>(0);
    const [sortField, setSortField] = useState<string>('id');
    const [sortDirection, setSortDirection] = useState<string>('DESC');

    const getUserRole = (role) => {
        const userRole = {
            HEAD_OF_LABORATORY: "Начальник лаборатории",
            VERIFIER: "Поверитель",
            ADMINISTRATOR: "Главный администратор"
        }

        // @ts-ignore
        return userRole[role]
    }

    const fetchData = async () => {
        setLoading(true)
        const {data} = await getUsers(currentPage, sortDirection, sortField)
        setUsers(data)
        setLoading(false)
    }

    // @ts-ignore
    useEffect(() => {
        fetchData().then()
    }, []);

    useEffect(() => {
        fetchData().then()
    }, [currentPage, sortDirection, sortField]);

    const updatePage = (page: number) => {
        setCurrentPage(page - 1)
    }

    const handleDeleteOpenModel = (userId: number) => {
        setDeleteUserId(userId)
        setIsDeleteModelOpened(true)
    }

    const handleDeleteUser = async () => {
        await deleteUser(deleteUserId)
        setDeleteUserId(null)
        setIsDeleteModelOpened(false)

        await fetchData().then()
    }

    const handleUpdateOpenModel = (userId: number) => {
        setUpdateUser(users && users.content.find(user => user.id === userId)!!)
        setIsUpdateModelOpened(true)
    }

    const handleSort = (fieldName: string) => {
        if (fieldName == sortField) {
            if (sortDirection === "ASC") {
                setSortDirection("DESC")
            } else {
                setSortDirection("ASC")
            }
        } else {
            setSortField(fieldName)
            setSortDirection("ASC")
        }
    }

    const isShowSort = (fieldName: string) => {
        if (fieldName == sortField) {
            return sortDirection === "DESC" ? '▲' : '▼'
        }
    }

    return (
        <>
            <Flex direction="column" gap={4}>
                <AddUserModal
                    isCreateModelOpened={isCreateModelOpened}
                    setIsCreateModelOpened={setIsCreateModelOpened}
                    fetchData={fetchData}
                />
                <UpdateUserModel
                    isUpdateModelOpened={isUpdateModelOpened}
                    setIsUpdateModelOpened={setIsUpdateModelOpened}
                    fetchData={fetchData}
                    user={updateUser}
                />
                <Flex justify="end" gap={5} align="center">
                    <Button
                        disabled={loading}
                        onClick={() => setIsCreateModelOpened(true)}>Добавить пользователя</Button>
                    <PaginationRoot
                        disabled={loading}
                        count={users.page.totalElements}
                        pageSize={35}
                        defaultPage={1}
                        onPageChange={(e: PageType) => updatePage(e.page)}
                    >
                        <HStack gap="4">
                            <PaginationPageText flex="1"/>
                            <PaginationPrevTrigger/>
                            <PaginationNextTrigger/>
                        </HStack>
                    </PaginationRoot>
                </Flex>
                {loading ?
                    <Loader/> :
                    <>
                        <Table.Root size="sm" interactive striped>
                            <Table.Header>
                                <Table.Row style={{cursor: 'pointer'}}>
                                    <Table.ColumnHeader
                                        width='145px' onClick={() => handleSort('id')}>Идентификатор {isShowSort('id')}</Table.ColumnHeader>
                                    <Table.ColumnHeader
                                        onClick={() => handleSort('login')}>Логин {isShowSort('login')}</Table.ColumnHeader>
                                    <Table.ColumnHeader
                                        onClick={() => handleSort('firstName')}>Имя {isShowSort('firstName')}</Table.ColumnHeader>
                                    <Table.ColumnHeader
                                        onClick={() => handleSort('lastName')}>Фамилия {isShowSort('lastName')}</Table.ColumnHeader>
                                    <Table.ColumnHeader
                                        onClick={() => handleSort('patronymicName')}>Отчество {isShowSort('patronymicName')}</Table.ColumnHeader>
                                    <Table.ColumnHeader
                                        width='185px' onClick={() => handleSort('userRole')}>Роль {isShowSort('userRole')}</Table.ColumnHeader>
                                    <Table.ColumnHeader
                                        onClick={() => handleSort('organizationName')}>Название организации {isShowSort('organizationName')}</Table.ColumnHeader>
                                    <Table.ColumnHeader
                                        onClick={() => handleSort('organizationInn')}>ИНН организации {isShowSort('organizationInn')}</Table.ColumnHeader>
                                    <Table.ColumnHeader
                                        onClick={() => handleSort('jobTitle')}>Должность {isShowSort('jobTitle')}</Table.ColumnHeader>
                                    <Table.ColumnHeader
                                        onClick={() => handleSort('brandCode')}>Шифр клейма {isShowSort('brandCode')}</Table.ColumnHeader>
                                    <Table.ColumnHeader></Table.ColumnHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {users.content && users.content.map((user) => (
                                    <Table.Row key={user.id}>
                                        <Table.Cell>{user.id}</Table.Cell>
                                        <Table.Cell>{user.login}</Table.Cell>
                                        <Table.Cell>{user.firstName}</Table.Cell>
                                        <Table.Cell>{user.lastName}</Table.Cell>
                                        <Table.Cell>{user.patronymicName}</Table.Cell>
                                        <Table.Cell>{getUserRole(user.userRole)}</Table.Cell>
                                        <Table.Cell>{user.organizationName}</Table.Cell>
                                        <Table.Cell>{user.organizationInn}</Table.Cell>
                                        <Table.Cell>{user.jobTitle}</Table.Cell>
                                        <Table.Cell>{user.brandCode}</Table.Cell>
                                        <Table.Cell textAlign="end">
                                            <Flex justify="end" gap={3} align="center">
                                                <Button variant="outline"
                                                        onClick={() => handleUpdateOpenModel(user.id)}><FaPenToSquare/></Button>
                                                <Button variant="outline"
                                                        onClick={() => handleDeleteOpenModel(user.id)}><FaTrashCan/></Button>
                                            </Flex>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table.Root>
                    </>}
                <HStack position="absolute" width="100%">
                    <DialogRoot open={isDeleteModelOpened} onInteractOutside={() => setIsDeleteModelOpened(false)}
                                placement="top">
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>Удаление пользователя</DialogTitle>
                            </DialogHeader>
                            <DialogBody>
                                <p>
                                    Вы точно хотите удалить пользователя из базы?
                                </p>
                            </DialogBody>
                            <DialogFooter>
                                <DialogActionTrigger asChild>
                                    <Button variant="outline"
                                            onClick={() => setIsDeleteModelOpened(false)}>Отмена</Button>
                                </DialogActionTrigger>
                                <Button onClick={handleDeleteUser}>Удалить</Button>
                            </DialogFooter>
                        </DialogContent>
                    </DialogRoot>
                </HStack>
            </Flex>
        </>
    )
}

export default User