// @ts-nocheck
// @ts-ignore
import {Flex, HStack, Table} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {PaginationNextTrigger, PaginationPageText, PaginationPrevTrigger, PaginationRoot} from "./ui/pagination";
import {PageType} from "../type/CommonType";
import Loader from "./Loader";
import {getEvents} from "../api/events";
import moment from 'moment';

const types = {
    DATA_CREATE: "Создали запись",
    DATA_UPDATE: "Обновили запись",
    DATA_DELETE: "Удалили запись",
    AUTH: "Логин в систему"
}

const table = {
    Authentication: "Авторизация",
    User: "Пользователь",
    ActivationKey: "Ключ активации",
    Organization: "Организация",
    "N/A": ""
}

const userRole = {
    HEAD_OF_LABORATORY: "Начальник лаборатории",
    VERIFIER: "Поверитель",
    ADMINISTRATOR: "Главный администратор"
}

const Events = () => {
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState({content: [{id: 1}], page: {totalElements: 1}});
    const [currentPage, setCurrentPage] = useState<number | null>(0);
    const [sortField, setSortField] = useState<string>('id');
    const [sortDirection, setSortDirection] = useState<string>('DESC');

    const fetchData = async () => {
        setLoading(true)
        const {data} = await getEvents(currentPage, sortDirection, sortField)
        setEvents(data)
        setLoading(false)
    }

    // @ts-ignore
    useEffect(() => {
        fetchData().then()
    }, []);

    useEffect(() => {
        fetchData().then()
    }, [currentPage, sortDirection, sortField]);

    const updatePage = (page: number) => {
        setCurrentPage(page - 1)
    }

    const handleSort = (fieldName: string) => {
        if (fieldName == sortField) {
            if (sortDirection === "ASC") {
                setSortDirection("DESC")
            } else {
                setSortDirection("ASC")
            }
        } else {
            setSortField(fieldName)
            setSortDirection("ASC")
        }
    }

    const isShowSort = (fieldName: string) => {
        if (fieldName == sortField) {
            return sortDirection === "DESC" ? '▲' : '▼'
        }
    }

    return (
        <Flex direction="column" gap={4}>
            <Flex justify="end" gap={5} align="center">
                <PaginationRoot
                    disabled={loading}
                    count={events.page.totalElements}
                    pageSize={35}
                    defaultPage={1}
                    onPageChange={(e: PageType) => updatePage(e.page)}
                >
                    <HStack gap="4">
                        <PaginationPageText flex="1"/>
                        <PaginationPrevTrigger/>
                        <PaginationNextTrigger/>
                    </HStack>
                </PaginationRoot>
            </Flex>
            {loading ?
                <Loader/> :
                <>
                    <Table.Root size="sm" interactive striped>
                        <Table.Header>
                            <Table.Row style={{cursor: 'pointer'}}>
                                <Table.ColumnHeader
                                    width='145px' onClick={() => handleSort('id')}>Идентификатор {isShowSort('id')}
                                </Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('timestamp')}>Время {isShowSort('timestamp')}</Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('type')}>Тип события {isShowSort('type')}</Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('entityName')}>Таблица {isShowSort('entityName')}</Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('user.organizationName')}>Наименование организации {isShowSort('user.organizationName')}</Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('user.lastName')}>ФИО пользователя {isShowSort('user.lastName')}</Table.ColumnHeader>
                                <Table.ColumnHeader onClick={() => handleSort('user.userRole')}>Роль пользователя {isShowSort('user.userRole')}</Table.ColumnHeader>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {events.content && events.content.map((event) => (
                                <Table.Row key={event.id}>
                                    <Table.Cell style={{padding: '20px 10px'}}>{event.id}</Table.Cell>
                                    <Table.Cell>{moment(event.timestamp).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
                                    <Table.Cell>{types[event.type]}</Table.Cell>
                                    <Table.Cell>{table[event.entityName]}</Table.Cell>
                                    <Table.Cell>{event.user && event.user.organizationName || ""}</Table.Cell>
                                    <Table.Cell>{event.user && `${event.user.lastName} ${event.user.firstName} ${event.user.patronymicName}`}</Table.Cell>
                                    <Table.Cell>{event.user && userRole[event.user.userRole]}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table.Root>
                </>}
        </Flex>
    )
}

export default Events