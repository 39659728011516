import {Tabs} from "@chakra-ui/react";
import React from "react";
import Organization from "./Organization";
import {isShowAdminContent} from "../utils/tokenUtils";
import User from "./User";
import Events from "./Events";
import Key from "./Key";

const AdminContent = () => {

    return (
        <Tabs.Root defaultValue={isShowAdminContent() ? "organization" : "users"} lazyMount unmountOnExit>
            <Tabs.List>
                {isShowAdminContent() && <Tabs.Trigger value="organization">Организации</Tabs.Trigger>}
                <Tabs.Trigger value="users">Пользователи</Tabs.Trigger>
                <Tabs.Trigger value="keys">Ключи активации</Tabs.Trigger>
                <Tabs.Trigger value="events">События системы</Tabs.Trigger>
            </Tabs.List>
            {isShowAdminContent() && <Tabs.Content value="organization"><Organization/></Tabs.Content>}
            <Tabs.Content value="users"><User/></Tabs.Content>
            <Tabs.Content value="keys"><Key/></Tabs.Content>
            <Tabs.Content value="events"><Events/></Tabs.Content>
        </Tabs.Root>
    )
}

export default AdminContent